<nav class="container-fluid e-header navbar navbar-expand-lg navbar-light bg-light" *ngIf="
    this.router.url != '/' &&
    this.router.url != '/user/landing' &&
    this.router.url != '/user/contact-us-landing' &&
    this.router.url != '/user/pricing-landing'
  ">
  <div class="row e-header__inner-wrapper">
    <div class="col-6 col-md-2">
      <a class="e-header__title" *ngIf="isAutheticated" routerLink="/user/dashboard"
        style="text-decoration: none">eSMS</a>
      <a class="e-header__title" *ngIf="!isAutheticated" routerLink="/login" style="text-decoration: none">eSMS</a>
    </div>
    <ng-container *ngIf="
        isAutheticated &&
        router.url !== '/login' &&
        router.url !== '/signup' &&
        router.url !== '/signup/pin-verify' &&
        router.url !== '/signup/get-pin/:mobile' &&
        router.url !== '/account/forgot-password' &&
        router.url !== '/account/forgot-password/enter-mobile' &&
        router.url !== '/account/forgot-password/enter-pin'
      ">
      <ul class="navbar-nav e-header__icon-wra col-6 col-md-2 order-sm-last d-flex justify-content-end">
        <li class="nav-item dropdown">
          <span class="e-btn-icon e-btn-icon--circle e-btn-icon--spacing-right" id="navbarDropdown" role="button"
            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="material-icons">notifications</i>
            <span class="e-btn-icon__circle e-btn-icon__circle--alert" *ngIf="
                this.account?.notification != null &&
                isUnReadNotifications(this.account?.notification)
              ">{{ countUnReadNotifications(this.account?.notification) }}</span>
          </span>
          <div class="e-menu e-ntf dropdown-menu" aria-labelledby="navbarDropdown">
            <div class="e-ntf__header">
              <span class="e-ntf__header-txt"><a routerLink="/account/notification" style="text-decoration: none">SEE
                  ALL</a></span>
            </div>
            <a routerLink="/account/notification" style="text-decoration: none"
              *ngIf="this.account?.notification != null">
              <ng-container *ngFor="let item of this.account?.notification; let i = index">
                <ng-container *ngIf="i <= 10">
                  <ng-container *ngIf="item.status == 1">
                    <div class="dropdown-item e-ntf__inner-wrapper">
                      <div class="e-ntf__txt">
                        <div class="e-menu__sec-txt">
                          {{
                          item?.created_time
                          | date: "yyyy-MM-dd HH:mm":"+0530"
                          }}
                        </div>
                        <div class="e-menu__primary-txt mt-1">
                          {{ item?.formal_message }}
                        </div>
                      </div>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="item.status == 0">
                    <div class="dropdown-item e-ntf__inner-wrapper e-ntf__inner-wrapper--new">
                      <div class="e-ntf__txt">
                        <div class="e-menu__sec-txt">
                          {{
                          item?.created_time
                          | date: "yyyy-MM-dd HH:mm":"+0530"
                          }}
                        </div>
                        <div class="e-menu__primary-txt mt-1">
                          {{ item?.formal_message }}
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </ng-container>
              </ng-container>
            </a>

            <div class="e-ntf__footer">
              <span class="e-ntf__footer-txt"><a routerLink="/account/notification" style="text-decoration: none">SEE
                  ALL</a></span>
            </div>
          </div>
        </li>
        <li class="nav-item dropdown">
          <span class="e-btn-icon e-btn-icon--circle" id="navbarDropdown2" role="button" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false">
            <i class="material-icons">person</i>
          </span>
          <div class="e-menu e-menu-profile dropdown-menu" aria-labelledby="navbarDropdown2">
            <div class="text-center e-menu__sec-txt">Logged in as</div>
            <div class="text-center e-menu__primary-txt">
              {{ this.getUserData()?.fname }}
            </div>
            <div class="dropdown-divider"></div>
            <div class="e-menu-btn-wrapper">
              <a class="e-body-txt e-body-txt--highlighted e-menu-btn-wrapper__btn" routerLink="/account/view-account"
                style="text-decoration: none">Account</a>
              <span class="e-menu-btn-wrapper__vl"></span>
              <a class="e-body-txt e-body-txt--highlighted e-menu-btn-wrapper__btn" (click)="logout()">Logout</a>
            </div>
          </div>
        </li>
      </ul>
      <ul class="navbar-nav mr-auto e-header-nav col-12 col-md-8">
        <li class="nav-item active">
          <button class="e-header-nav__item" mat-button routerLink="/campaign/list"
            routerLinkActive="e-header-nav__item--active">
            Campaigns
          </button>
        </li>
        <!-- <li class="nav-item active">
          <button class="e-header-nav__item" mat-button routerLink="/inbox/list"
            routerLinkActive="e-header-nav__item--active">
            Inbox
          </button>
        </li> -->
        <li class="nav-item active">
          <button class="e-header-nav__item" mat-button routerLink="/contact/list"
            routerLinkActive="e-header-nav__item--active">
            Contact List
          </button>
        </li>
        <li class="nav-item active">
          <button class="e-header-nav__item" mat-button routerLink="/template/list"
            routerLinkActive="e-header-nav__item--active">
            Template List
          </button>
        </li>

        <li class="nav-item">
          <button class="e-header-nav__item" routerLink="/account/my-wallet" mat-button
            routerLinkActive="e-header-nav__item--active">
            Wallet
          </button>
        </li>
        <li class="nav-item">
          <button class="e-header-nav__item" mat-button routerLink="/account/report/per-mobile" [ngClass]="{
              'e-header-nav__item--active':
                this.router.url == '/account/report/all-campaigns' ||
                this.router.url == '/account/report/per-mobile' ||
                this.router.url == '/account/report/enterprise-dashboard' ||
                this.router.url == '/account/report/usage'
            }">
            Reports
          </button>
        </li>
        <!-- <li class="nav-item">
          <button
            class="e-header-nav__item"
            mat-button
            routerLink="/account/report/all-campaigns"
            routerLinkActive="e-header-nav__item--active"
          >
            Search
          </button>
        </li> -->

        <li class="nav-item">
          <button class="e-header-nav__item" mat-button routerLink="/account/new-mask"
            routerLinkActive="e-header-nav__item--active">
            Request masks
          </button>
        </li>
        <li class="nav-item">
          <button class="e-header-nav__item" mat-button routerLink="/account/view-account"
            routerLinkActive="e-header-nav__item--active">
            Account info
          </button>
        </li>
        <li class="nav-item">
          <button class="e-header-nav__item" mat-button routerLink="/account/notification"
            routerLinkActive="e-header-nav__item--active">
            Notification
          </button>
        </li>
      </ul>
    </ng-container>
  </div>
</nav>
