import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
} from "@angular/core";
import { AuthInterceptor } from "src/app/auth/auth-interceptor";
import { HttpClient } from "@angular/common/http";
import { CampaignService } from "../../campaign.service";
import { NumberMimeType } from "./mime-type.validator";

import {
  FormGroup,
  FormGroupDirective,
  FormControl,
  Validators,
  AbstractControl,
} from "@angular/forms";
import { Route } from "@angular/compiler/src/core";
import { Router } from "@angular/router";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { SuccessComponent } from "src/app/success/success.component";
import * as moment from "moment";
import * as momentT from "moment-timezone";
import * as fileSaver from "file-saver";
import { DatePipe } from "@angular/common";
import { SuccessDialogBoxComponent } from "src/app/pop-up-messages/success-dialog-box/success-dialog-box.component";
import { ErrorDialogBoxComponent } from "src/app/pop-up-messages/error-dialog-box/error-dialog-box.component";
import { AuthService } from "src/app/auth/auth.service";
import { AppSettings } from "../.././../config";

export interface DialogData {
  campaignId: string;
  campaignCost: string;
  walletBalance: string;
  chargeableCost: string;
  campaignStatus: string;
  mobile: number;
  duplicatesRemoved: number;
  invalidNumbers: number;
}

@Component({
  selector: "app-personalize-sms",
  templateUrl: "./personalize-sms.component.html",
  styleUrls: ["./personalize-sms.component.css"],
})
export class PersonalizeSmsComponent implements OnInit {
  @ViewChild("textArea", { static: false }) textArea: ElementRef;
  hostIpWithPort = AppSettings.hostIpWithPort;

  btn2 = false;
  btn3 = false;
  btn4 = false;
  btn5 = false;
  btn6 = false;

  msg;
  textMsg = null;
  emptyMessage = false;
  keyValueUsed = false;

  masks = [];
  form: FormGroup;
  isLoading = false;
  messageRemLength = 160;
  numberOfMessages = 1;
  msg_default_size = 1;
  msg_multiplier = 1;
  file_select = false;
  minDate;
  maxDate;
  maskName = null;
  signatureText = "";
  account;
  contactList;
  templateList;
  constructor(
    private http: HttpClient,
    private campaignService: CampaignService,
    private router: Router,
    private matDialog: MatDialog,
    private authService: AuthService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    const date = new Date();
    const currentYear = date.getFullYear();
    const currentMonth = date.getMonth();
    const currentDay = date.getDate();
    this.minDate = new Date(currentYear - 0, currentMonth, currentDay);
    this.maxDate = new Date(currentYear + 0, currentMonth, currentDay + 7);
  }

  ngOnInit(): void {
    this.campaignService.getAllMasks().subscribe(
      (response: any) => {
        // console.log(response.contactList);
        this.contactList = response.contactList;
        this.templateList = response.messageTemplates;
        this.masks.push({
          value: response.defaultMask,
          viewValue: response.defaultMask,
        });
        for (var i = 0; i < response.additionalMask.length; i++) {
          if (response.additionalMask[i].mask_status == 1) {
            this.masks.push({
              value: response.additionalMask[i].mask,
              viewValue: response.additionalMask[i].mask,
            });
          }
        }
      },
      (error) => {
        console.log(error);
      }
    );
    //form validations
    this.form = new FormGroup({
      sourceAddress: new FormControl(null, {
        validators: [
          Validators.required,
          Validators.pattern("^[a-zA-Z0-9 _~'&-.@]*$"),
        ],
      }),
      campaignName: new FormControl(null, {
        validators: [
          Validators.required,
          Validators.minLength(3),
          Validators.pattern("^[a-zA-Z0-9-.,_'\"`|:() /]*$"),
        ],
      }),
      fileSelect: new FormControl(null, {
        validators: [Validators.required],
        asyncValidators: [NumberMimeType],
        // updateOn: "submit",
      }),
      fileName: new FormControl(null, {
        validators: [Validators.required],
      }),
      isOptOutRequired: new FormControl(null, {
        validators: [],
      }),
      scheduleDate: new FormControl(null, {
        validators: [
          Validators.required,
          Validators.maxLength(10),
          Validators.minLength(8),
        ],
        // updateOn: "submit",
      }),
      scheduleTime: new FormControl(null, {
        validators: [
          Validators.required,
          Validators.minLength(7),
          Validators.maxLength(8),
          Validators.pattern("^[AMamPMpm 0-9:]*$"),
        ],
      }),
      // expiryDate: new FormControl(null, {
      //   validators: [
      //     Validators.required,
      //     Validators.maxLength(10),
      //     Validators.minLength(8),
      //   ],
      // }),
      // expiryTime: new FormControl(null, {
      //   validators: [
      //     Validators.required,
      //     Validators.minLength(7),
      //     Validators.maxLength(8),
      //     Validators.pattern("^[AMamPMpm 0-9:]*$"),
      //   ],
      // }),
      message: new FormControl("", {
        validators: [
          // Validators.required,
          // Validators.minLength(3),
          // // Validators.pattern("^[a-zA-Z0-9-.,_'\"`|:() /]*$"),
        ],
      }),
      scheduleNow: new FormControl(null, {
        validators: [],
      }),
      templateList: new FormControl(null, {
        validators: [],
      }),
      blackOutLogic: new FormControl(null, {
        validators: [Validators.required],
      }),
    });
    this.authService.getAccountDetails().subscribe(
      (response: any) => {
        this.account = response.userData;
        if (this.account?.account_ussage == 1) {
          this.form.controls.blackOutLogic.setValue(0);
          this.form.get("blackOutLogic").setValidators([]);
        } else if (this.account?.account_ussage == 2) {
          this.form.controls.blackOutLogic.setValue(null);
          this.form.get("blackOutLogic").setValidators([Validators.required]);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  scheduleNowChange() {
    const newTime = momentT().tz("Asia/kolkata").format("HH:mm A");
    const newDate = momentT().tz("Asia/kolkata").format("YYYY-MM-DD");
    if (this.form.value.scheduleNow == true) {
      //schedule time
      this.form.patchValue({ scheduleTime: newTime });
      this.form.get("scheduleTime").updateValueAndValidity();
      //schedule date
      this.form.patchValue({ scheduleDate: newDate });
      this.form.get("scheduleDate").updateValueAndValidity();

      this.form.get("scheduleTime").disable();
      this.form.get("scheduleDate").disable();
    } else {
      this.form.patchValue({ scheduleTime: null });
      this.form.get("scheduleTime").updateValueAndValidity();
      this.form.patchValue({ scheduleDate: null });
      this.form.get("scheduleDate").updateValueAndValidity();

      this.form.get("scheduleTime").enable();
      this.form.get("scheduleDate").enable();
    }
  }
  createCampaign() {
    if (!this.textMsg || this.textMsg == "") {
      this.emptyMessage = true;
      return;
    }

    var msg = "you should select column ";
    var columns = [];

    if (
      this.textMsg.includes("#eSMS_VAR_6") &&
      (!this.textMsg.includes("#eSMS_VAR_5") ||
        !this.textMsg.includes("#eSMS_VAR_4") ||
        !this.textMsg.includes("#eSMS_VAR_3") ||
        !this.textMsg.includes("#eSMS_VAR_2"))
    ) {
      if (!this.textMsg.includes("#eSMS_VAR_5")) {
        columns.push("[5]");
      }
      if (!this.textMsg.includes("#eSMS_VAR_4")) {
        columns.push("[4]");
      }
      if (!this.textMsg.includes("#eSMS_VAR_3")) {
        columns.push("[3]");
      }
      if (!this.textMsg.includes("#eSMS_VAR_2")) {
        columns.push("[2]");
      }

      msg = msg + columns.join(",");
      this.matDialog.open(ErrorDialogBoxComponent, {
        width: "532px",
        data: { message: msg },
      });
      return;
    } else if (
      this.textMsg.includes("#eSMS_VAR_5") &&
      (!this.textMsg.includes("#eSMS_VAR_4") ||
        !this.textMsg.includes("#eSMS_VAR_3") ||
        !this.textMsg.includes("#eSMS_VAR_2"))
    ) {
      if (!this.textMsg.includes("#eSMS_VAR_4")) {
        columns.push("[4]");
      }
      if (!this.textMsg.includes("#eSMS_VAR_3")) {
        columns.push("[3]");
      }
      if (!this.textMsg.includes("#eSMS_VAR_2")) {
        columns.push("[2]");
      }

      msg = msg + columns.join(",");
      this.matDialog.open(ErrorDialogBoxComponent, {
        width: "532px",
        data: { message: msg },
      });
      return;
    } else if (
      this.textMsg.includes("#eSMS_VAR_4") &&
      (!this.textMsg.includes("#eSMS_VAR_3") ||
        !this.textMsg.includes("#eSMS_VAR_2"))
    ) {
      if (!this.textMsg.includes("#eSMS_VAR_3")) {
        columns.push("[3]");
      }
      if (!this.textMsg.includes("#eSMS_VAR_2")) {
        columns.push("[2]");
      }

      msg = msg + columns.join(",");
      this.matDialog.open(ErrorDialogBoxComponent, {
        width: "532px",
        data: { message: msg },
      });
      return;
    } else if (
      this.textMsg.includes("#eSMS_VAR_3") &&
      !this.textMsg.includes("#eSMS_VAR_2")
    ) {
      if (!this.textMsg.includes("#eSMS_VAR_2")) {
        columns.push("[2]");
      }

      msg = msg + columns.join(",");
      this.matDialog.open(ErrorDialogBoxComponent, {
        width: "532px",
        data: { message: msg },
      });
      return;
    }

    this.file_select = true;
    if (this.form.invalid || this.emptyMessage || this.keyValueUsed) {
      return;
    }
    let scheduleNewTime = this.form.value.scheduleTime;
    if (this.form.value.scheduleNow == true) {
      scheduleNewTime = momentT()
        .add(2, "minutes")
        .tz("Asia/kolkata")
        .format("HH:mm A");
    }
    // console.log(this.form);
    this.isLoading = true;
    //number file
    this.campaignService
      .createTemplatedMsgCampaign(
        this.form.value.sourceAddress,
        this.form.value.campaignName,
        this.form.value.isOptOutRequired ? "1" : "0",
        (countOccurences(this.textMsg, "#eSMS_VAR") + 1).toString(),
        this.form.value.fileSelect,
        this.form.value.scheduleDate,
        scheduleNewTime,
        // this.form.value.expiryDate,
        // this.form.value.expiryTime,
        this.textMsg,
        this.form.value.scheduleNow ? "1" : "0",
        this.form.value.blackOutLogic
      )
      .subscribe(
        (response: any) => {
          this.isLoading = false;
          this.changeDetectorRef.detectChanges();
          this.textArea.nativeElement.innerHTML = this.msg;
          this.onNameChange();

          if (response.status == "success") {
            this.file_select = false;
            // console.log(response);
            // this.router.navigate([
            //   "/account/pay/campaign-pay",
            //   response.campaignId,
            //   response.campaignCost,
            //   response.chargeableCost,
            //   response.walletBalance,
            //   response.chargeableCost,
            // ]);
            //how capture success response
            // this.matDialog.open(SuccessComponent, {
            //   width: "542px",
            //   data: { message: response.comment },
            // });
            const dialogRef = this.matDialog.open(
              CampaignPersonalizePaymentComponent,
              {
                width: "630px",
                height: "630px",
                data: {
                  campaignId: response.campaignId,
                  campaignCost: response.campaignCost,
                  walletBalance: response.walletBalance,
                  chargeableCost: response.chargeableCost,
                  campaignStatus: response.campaignStatus,
                  mobile: response.userMobile,
                  duplicatesRemoved: response.duplicatesRemoved,
                  invalidNumbers: response.invalidNumbers,
                },
              }
            );
          } else {
            this.isLoading = false;
            this.changeDetectorRef.detectChanges();
            this.textArea.nativeElement.innerHTML = this.msg;
            this.onNameChange();
            console.log("invalid");
          }
        },
        (error) => {
          this.isLoading = false;
          this.changeDetectorRef.detectChanges();
          this.textArea.nativeElement.innerHTML = this.msg;
          this.onNameChange();
          console.log(error);
        }
      );
    //console.log(this.form);
  }

  onNumberClick(event: Event) {
    this.file_select = true;
  }
  onExcelPicked(event: Event) {
    const file = (event.target as HTMLInputElement).files[0];
    this.form.patchValue({ fileSelect: file });
    this.form.get("fileSelect").updateValueAndValidity();
    let fileName = file.name;
    if (fileName.length > 45) {
      fileName = fileName.toString().substr(0, 45) + "...";
    }
    this.form.patchValue({ fileName: fileName });
    this.form.get("fileName").updateValueAndValidity();
  }
  messageCounterUpdate() {
    if (this.form.value.message != null) {
      if (hasUnicode(this.form.value.message)) {
        this.msg_default_size = 70;
        this.setMessageLength();
      } else {
        this.msg_default_size = 160;
        this.setMessageLength();
      }
    } else {
      this.msg_default_size = 160;
      this.setMessageLength();
    }
  }
  //function to send message counter in the UI
  setMessageLength() {
    //+ (this.signatureText.length + 1)
    if (this.account?.account_ussage == 2) {
      //promotional
      var msg_temp_length =
        this.form.value.message.length + this.signatureText.length;
    } else {
      //transaction
      var msg_temp_length = this.form.value.message.length;
    }

    var quotient = Math.floor(msg_temp_length / this.msg_default_size);
    var remainder =
      (msg_temp_length - this.msg_default_size * quotient) %
      this.msg_default_size;
    if (msg_temp_length % this.msg_default_size == 0) {
      if (
        msg_temp_length == this.msg_default_size * quotient &&
        msg_temp_length != 0
      ) {
        this.messageRemLength = 0;
        this.numberOfMessages = quotient;
      } else {
        this.messageRemLength = this.msg_default_size - remainder;
        this.numberOfMessages = quotient;
      }
    } else {
      this.messageRemLength = this.msg_default_size - remainder;
      this.numberOfMessages = quotient + 1;
    }
    //setting number of messages to 1 when ever message box is empty
    if (this.form.value.message.length == 0) {
      this.numberOfMessages = 1;
    }
  }
  maskDropDownChange() {
    if (this.account?.account_ussage == 2) {
      this.maskName = this.form.value.sourceAddress;
      this.signatureText =
        " *StopAd? SMS BL " + this.maskName + " to 077XXXXXXX*";
      this.messageCounterUpdate();
    } else {
      //for tranaction no message displaying
      this.maskName = this.form.value.sourceAddress;
      this.signatureText = "";
      this.messageCounterUpdate();
    }
  }

  setTemplateMessage() {
    this.textArea.nativeElement.innerHTML = this.form.get("templateList").value;
    this.onNameChange();
  }

  insertImage2() {
    const img2 =
      '<img src="assets/images/col2_tag.png" id="img_col2" width="60"/>';
    document.execCommand("insertHTML", false, img2);
    this.textArea.nativeElement.focus();
  }
  insertImage3() {
    const img3 =
      '<img src="assets/images/col3_tag.png" id="img_col3" width="60"/>';
    document.execCommand("insertHTML", false, img3);
    this.textArea.nativeElement.focus();
  }
  insertImage4() {
    const img4 =
      '<img src="assets/images/col4_tag.png" id="img_col4" width="60"/>';
    document.execCommand("insertHTML", false, img4);
    this.textArea.nativeElement.focus();
  }
  insertImage5() {
    const img5 =
      '<img src="assets/images/col5_tag.png" id="img_col5" width="60"/>';
    document.execCommand("insertHTML", false, img5);
    this.textArea.nativeElement.focus();
  }
  insertImage6() {
    const img6 =
      '<img src="assets/images/col6_tag.png" id="img_col6" width="60"/>';
    document.execCommand("insertHTML", false, img6);
    this.textArea.nativeElement.focus();
  }

  imgRemove(id) {
    document.getElementById(id).remove();
    this.textArea.nativeElement.focus();
  }

  onNameChange() {
    this.msg = this.textArea.nativeElement.innerHTML;
    // console.log(this.msg);

    if (this.msg.startsWith("<div>")) {
      this.textMsg = this.msg
        .replaceAll("</div><div>", "\n")
        .replaceAll('<div><span style="font-size: 1rem;">', "\n")
        .replaceAll("</span><div>", "\n")
        .replaceAll("<br>", "")
        .replaceAll("</div>", "")
        .replaceAll("<div>", "")
        .replaceAll("&amp;", "&")
        .replaceAll("&nbsp;", " ")
        .replaceAll(
          document.getElementById("img_col2")
            ? document.getElementById("img_col2").outerHTML
            : "!@#",
          "#eSMS_VAR_2"
        )
        .replaceAll(
          document.getElementById("img_col3")
            ? document.getElementById("img_col3").outerHTML
            : "!@#",
          "#eSMS_VAR_3"
        )
        .replaceAll(
          document.getElementById("img_col4")
            ? document.getElementById("img_col4").outerHTML
            : "!@#",
          "#eSMS_VAR_4"
        )
        .replaceAll(
          document.getElementById("img_col5")
            ? document.getElementById("img_col5").outerHTML
            : "!@#",
          "#eSMS_VAR_5"
        )
        .replaceAll(
          document.getElementById("img_col6")
            ? document.getElementById("img_col6").outerHTML
            : "!@#",
          "#eSMS_VAR_6"
        )
        .replaceAll(/<[^>]*>/g, "");
    } else {
      this.textMsg = this.msg
        .replace("<div>", "\n")
        .replaceAll("</div><div>", "\n")
        .replaceAll('<div><span style="font-size: 1rem;">', "\n")
        .replaceAll("</span><div>", "\n")
        .replaceAll("<br>", "")
        .replaceAll("</div>", "")
        .replaceAll("<div>", "")
        .replaceAll("&amp;", "&")
        .replaceAll("&nbsp;", " ")
        .replaceAll(
          document.getElementById("img_col2")
            ? document.getElementById("img_col2").outerHTML
            : "!@#",
          "#eSMS_VAR_2"
        )
        .replaceAll(
          document.getElementById("img_col3")
            ? document.getElementById("img_col3").outerHTML
            : "!@#",
          "#eSMS_VAR_3"
        )
        .replaceAll(
          document.getElementById("img_col4")
            ? document.getElementById("img_col4").outerHTML
            : "!@#",
          "#eSMS_VAR_4"
        )
        .replaceAll(
          document.getElementById("img_col5")
            ? document.getElementById("img_col5").outerHTML
            : "!@#",
          "#eSMS_VAR_5"
        )
        .replaceAll(
          document.getElementById("img_col6")
            ? document.getElementById("img_col6").outerHTML
            : "!@#",
          "#eSMS_VAR_6"
        )
        .replaceAll(/<[^>]*>/g, "");
    }

    if (!this.textMsg || this.textMsg == "") {
      this.emptyMessage = true;
    } else {
      this.emptyMessage = false;
    }

    if (this.msg.includes("#eSMS_VAR")) {
      this.keyValueUsed = true;
    } else {
      this.keyValueUsed = false;
    }

    // console.log(document.getElementById('img_col2')?document.getElementById('img_col2').outerHTML:"");
    // console.log(this.textMsg);
    // console.log(window["clipboardData"].getData("text"));

    if (document.getElementById("img_col2")) {
      this.btn2 = true;
    } else if (!document.getElementById("img_col2")) {
      this.btn2 = false;
    }

    if (document.getElementById("img_col3")) {
      this.btn3 = true;
    } else if (!document.getElementById("img_col3")) {
      this.btn3 = false;
    }

    if (document.getElementById("img_col4")) {
      this.btn4 = true;
    } else if (!document.getElementById("img_col4")) {
      this.btn4 = false;
    }

    if (document.getElementById("img_col5")) {
      this.btn5 = true;
    } else if (!document.getElementById("img_col5")) {
      this.btn5 = false;
    }

    if (document.getElementById("img_col6")) {
      this.btn6 = true;
    } else if (!document.getElementById("img_col6")) {
      this.btn6 = false;
    }
  }

  pasteEvent(event: ClipboardEvent) {
    let clipboardText = event.clipboardData.getData("text/html");

    var msg = "Already added column ";
    var columns = [];

    if (
      (document.getElementById("img_col2") &&
        clipboardText.includes('id="img_col2" width="60"')) ||
      (document.getElementById("img_col3") &&
        clipboardText.includes('id="img_col3" width="60"')) ||
      (document.getElementById("img_col4") &&
        clipboardText.includes('id="img_col4" width="60"')) ||
      (document.getElementById("img_col5") &&
        clipboardText.includes('id="img_col5" width="60"')) ||
      (document.getElementById("img_col6") &&
        clipboardText.includes('id="img_col6" width="60"'))
    ) {
      if (
        document.getElementById("img_col2") &&
        clipboardText.includes('id="img_col2" width="60"')
      ) {
        columns.push("[2]");
      }
      if (
        document.getElementById("img_col3") &&
        clipboardText.includes('id="img_col3" width="60"')
      ) {
        columns.push("[3]");
      }
      if (
        document.getElementById("img_col4") &&
        clipboardText.includes('id="img_col4" width="60"')
      ) {
        columns.push("[4]");
      }
      if (
        document.getElementById("img_col5") &&
        clipboardText.includes('id="img_col5" width="60"')
      ) {
        columns.push("[5]");
      }
      if (
        document.getElementById("img_col6") &&
        clipboardText.includes('id="img_col6" width="60"')
      ) {
        columns.push("[6]");
      }

      msg = msg + columns.join(",");

      this.matDialog.open(ErrorDialogBoxComponent, {
        width: "532px",
        data: { message: msg },
      });

      event.clipboardData.clearData();
      return;
    }
  }

  replaceSignature(s: string) {
    return s && s.replace("077XXXXXXX", "9010");
  }
}
function hasUnicode(str) {
  for (var i = 0; i < str.length; i++) {
    if (str.charCodeAt(i) > 127) return true;
  }
  return false;
}
@Component({
  selector: "campiagn-payment-popup-dialog",
  templateUrl: "./payment-modal.component.html",
  styleUrls: ["./payment-modal.component.css"],
})
export class CampaignPersonalizePaymentComponent implements OnInit {
  selectedPaymentOption = null;
  paymentType;
  ngOnInit() {
    this.paymentType = this.authService.getPaymentType();
    if (this.data.campaignId == null) {
      this.closeDialog();
    }
  }
  constructor(
    public dialogRef: MatDialogRef<CampaignPersonalizePaymentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private campaignService: CampaignService,
    private router: Router,
    private matDialog: MatDialog,
    private authService: AuthService
  ) {
    dialogRef.disableClose = true;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  closeDialog() {
    this.router.navigate(["/campaign/view", this.data.campaignId]);
    this.dialogRef.close();
  }
  walletOption() {
    this.selectedPaymentOption = 0;
  }
  ezCashOption() {
    this.selectedPaymentOption = 1;
  }
  packageOption() {
    this.selectedPaymentOption = 4;
  }
  addToBillOption() {
    this.selectedPaymentOption = 2;
  }
  initiatePayment() {
    // console.log(this.selectedPaymentOption);
    if (
      this.selectedPaymentOption == null ||
      (this.selectedPaymentOption != 0 &&
        this.selectedPaymentOption != 1 &&
        this.selectedPaymentOption != 2 &&
        this.selectedPaymentOption != 4)
    ) {
      return;
    }

    //0 stands for campaign payment
    let type = 0;
    //campign value is handled in backend
    //setting defualt value to 0
    let default_val = 0;
    this.campaignService
      .initiatePayment(
        +this.data.campaignId,
        +this.selectedPaymentOption,
        +type,
        +default_val
      )
      .subscribe(
        (response: any) => {
          // console.log(response);
          this.closeDialog();
          this.router.navigate(["/campaign/view", this.data.campaignId]);
          //how capture success response
          this.matDialog.open(SuccessDialogBoxComponent, {
            width: "532px",
            data: { message: response.comment },
          });
        },
        (error) => {
          console.log(error);
        }
      );
  }
}

function countOccurences(string, word) {
  return string.split(word).length - 1;
}
