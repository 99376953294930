import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LoginComponent } from "./auth/login/login.component";
import { SignupComponent } from "./auth/signup/signup.component";
import { PinVerifyComponent } from "./auth/signup/pin-verify/pin-verify.component";
import { CampaignCreateComponent } from "./campaign/campaign-create/campaign-create.component";
import { CampaignListComponent } from "./campaign/campaign-list/campaign-list.component";
import { CampaignViewComponent } from "./campaign/campaign-view/campaign-view.component";
import { NewMaskComponent } from "./account/new-mask/new-mask.component";
import { UserWalletComponent } from "./account/user-wallet/user-wallet.component";
import { UserTransactionHistoryComponent } from "./account/user-transaction-history/user-transaction-history.component";
import { ForgotPasswordComponent } from "./account/forgot-password/forgot-password.component";
import { VerifyMobileComponent } from "./account/forgot-password/verify-mobile/verify-mobile.component";
import { PasswordVerifyPinComponent } from "./account/forgot-password/password-verify-pin/password-verify-pin.component";
import { AccountInfoComponent } from "./account/account-info/account-info.component";
import { AllCampaignsReportComponent } from "./campaign/reports/all-campaigns-report/all-campaigns-report.component";
import { AllCampaignsPerMobileComponent } from "./campaign/reports/all-campaigns-per-mobile/all-campaigns-per-mobile.component";
import { from } from "rxjs";
import { PayCampaignOptionComponent } from "./auth/pay-campaign-option/pay-campaign-option.component";
import { AuthGuard } from "./auth/auth.guard";
import { GetPinComponent } from "./auth/signup/get-pin/get-pin.component";
import { CampaignEditComponent } from "./campaign/campaign-edit/campaign-edit.component";
import { AccountEditComponent } from "./account/account-edit/account-edit.component";
import { UserDashboardComponent } from "./account/user-dashboard/user-dashboard.component";
import { LandingComponent } from "./landing/landing.component";
import { NotificationComponent } from "./account/notification/notification.component";
import { ChangePasswordGetPinComponent } from "./account/change-password/change-password-get-pin/change-password-get-pin.component";
import { ChangePasswordVerifyPinComponent } from "./account/change-password/change-password-verify-pin/change-password-verify-pin.component";
import { ChangePasswordAddNewPasswordComponent } from "./account/change-password/change-password-add-new-password/change-password-add-new-password.component";
import { ListViewComponent } from "./contact-list/list-view/list-view.component";
import { CreateListComponent } from "./contact-list/create-list/create-list.component";
import { ViewListComponent } from "./contact-list/view-list/view-list.component";
import { PricingLandingComponent } from "./pricing-landing/pricing-landing.component";
import { ContactUsLandingComponent } from "./contact-us-landing/contact-us-landing.component";
import { BulkCampaignComponent } from "./campaign/campaign-list/bulk-campaign/bulk-campaign.component";
import { CampaignsSearchComponent } from "./campaign/campaign-list/bulk-campaign/campaigns-search/campaigns-search.component";
import { ContactListSearchComponent } from "./contact-list/list-view/contact-list-search/contact-list-search.component";
import { UserWalletSearchComponent } from "./account/user-wallet/user-wallet-search/user-wallet-search.component";
import { InboxSearchComponent } from "./inbox/inbox-search/inbox-search.component";
import { InboxComponent } from "./inbox/inbox.component";
import { CreateTemplateListComponent } from "./template-list/create-template-list/create-template-list.component";
import { TemplateListViewComponent } from "./template-list/template-list-view/template-list-view.component";
import { ViewTemplateListComponent } from "./template-list/view-template-list/view-template-list.component";
import { UserEnterpriseDashboardComponent } from "./campaign/reports/user-enterprise-dashboard/user-enterprise-dashboard.component";
import { UserUsageReportComponent } from "./campaign/reports/user-usage-report/user-usage-report.component";
import { TermsAndConditionsComponent } from "./terms-and-conditions/terms-and-conditions.component";
import { TwoStepVerifyEnterPinComponent } from "./auth/two-step-verify-enter-pin/two-step-verify-enter-pin.component";

const routes: Routes = [
  { path: "", component: LandingComponent },
  { path: "terms-and-conditions", component: TermsAndConditionsComponent },
  { path: "user/landing", component: LandingComponent },
  { path: "user/contact-us-landing", component: ContactUsLandingComponent },
  { path: "user/pricing-landing", component: PricingLandingComponent },
  { path: "login", component: LoginComponent },
  { path: "signup", component: SignupComponent },
  { path: "signup/pin-verify", component: PinVerifyComponent },
  { path: "signup/get-pin/:mobile", component: GetPinComponent },
  {
    path: "user/two-step-verification/enter-pin",
    component: TwoStepVerifyEnterPinComponent,
  },
  // {
  //   path: "campaign/create",
  //   component: CampaignCreateComponent,
  //   canActivate: [AuthGuard],
  // },
  {
    path: "campaign/list",
    component: BulkCampaignComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "campaign/list/all",
    component: BulkCampaignComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "campaign/list/search",
    component: CampaignsSearchComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "campaign/create",
    component: CampaignListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "campaign/view/:id",
    component: CampaignViewComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "campaign/edit/:id",
    component: CampaignEditComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "user/dashboard",
    component: UserDashboardComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "campaign/report/all",
    component: CampaignListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "campaign/report/mobile",
    component: CampaignListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "account/view-account",
    component: AccountInfoComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "account/edit-account",
    component: AccountEditComponent,
    canActivate: [AuthGuard],
  },

  {
    path: "account/new-mask",
    component: NewMaskComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "account/my-wallet",
    component: UserWalletComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "account/my-wallet/all",
    component: UserWalletComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "account/my-wallet/search",
    component: UserWalletSearchComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "account/expense-tracker",
    component: CampaignListComponent,
    canActivate: [AuthGuard],
  },
  { path: "account/forgot-password", component: ForgotPasswordComponent },
  {
    path: "account/forgot-password/enter-mobile",
    component: VerifyMobileComponent,
  },
  {
    path: "account/forgot-password/enter-pin/:mobile",
    component: PasswordVerifyPinComponent,
  },
  {
    path: "account/report/all-campaigns",
    component: AllCampaignsReportComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "account/report/per-mobile",
    component: AllCampaignsPerMobileComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "account/report/enterprise-dashboard",
    component: UserEnterpriseDashboardComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "account/report/usage",
    component: UserUsageReportComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "account/pay/campaign-pay/:campaignId/:campaignCost/:chargeableCost/:walletBalance/:campaignStatus",
    component: PayCampaignOptionComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "account/notification",
    component: NotificationComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "account/change-password/get-pin/:mobile",
    component: ChangePasswordGetPinComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "account/change-password/verify-pin/:mobile",
    component: ChangePasswordVerifyPinComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "account/change-password/update-new-password",
    component: ChangePasswordAddNewPasswordComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "contact/list",
    component: ListViewComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "contact/list/all",
    component: ListViewComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "contact/list/search",
    component: ContactListSearchComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "template/list",
    component: TemplateListViewComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "template/list/all",
    component: TemplateListViewComponent,
    canActivate: [AuthGuard],
  },
  // {
  //   path: "inbox/list",
  //   component: InboxComponent,
  //   canActivate: [AuthGuard],
  // },
  // {
  //   path: "inbox/list/all",
  //   component: InboxComponent,
  //   canActivate: [AuthGuard],
  // },
  // {
  //   path: "inbox/list/search",
  //   component: InboxSearchComponent,
  //   canActivate: [AuthGuard],
  // },
  {
    path: "contact/create-list",
    component: CreateListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "template/create-list",
    component: CreateTemplateListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "contact/view-list/:id",
    component: ViewListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "template/view-list/:id",
    component: ViewTemplateListComponent,
    canActivate: [AuthGuard],
  },
  { path: "**", redirectTo: "/user/dashboard" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
